import TextMaskComponent from '../components/TextMask/viewer/TextMask';


const TextMask = {
  component: TextMaskComponent
};


export const components = {
  ['TextMask']: TextMask
};

